import * as React from 'react';
import { instance } from '../../index';
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from 'react';

const currentUserStyle = {
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "1.5rem", 
  letterSpacing: "0.005rem",
  display: "flex",
  alignItems: "center"
};

const avatarStyle: React.CSSProperties = {
  backgroundColor: "#D9D9D9",
  minWidth: "38px",
  minHeight:"30px",
  maxWidth: "38px",
  maxHeight:"30px",
  borderRadius: "50%",
  textAlign: "center",
  paddingTop: "8px",
  color: "rgba(0, 0, 0, 0.80)",
  fontSize: "0.875rem",
  fontWeight: "600",
  lineHeight: "normal",
  letterSpacing: "0.005rem",
  marginRight: "0.5rem"
};

export const CurrentUser = () => {
  const [user, setUser] = useState('');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    // declare the async data fetching function
    const decoded = (jwtDecode(instance.root.getData('accessToken')) as any);
    setUser(decoded.given_name + ' ' + decoded.family_name);
    setInitials(decoded.given_name.substring(0, 1) + decoded.family_name.substring(0, 1));
  }, [])

  return (
    <div style={ currentUserStyle }>
      <div style={avatarStyle}>{initials}</div>
      <div>{user}</div>
    </div>
  );
};