import { graphQLQuery } from "../../Services/GraphQL";

export const gqlLibraryItems : graphQLQuery = {
  query: `query {
    libraryItems {
      id
      item
      category
      contentType
      url
    }
  }`,
  errorMessage: "Error loading library items.",
  collection: "libraryItems"
};

export const gqlQueryLibraryItemDependencies : graphQLQuery = {
  query: `query libraryItems($libraryItemIds: [UUID!]) {
    libraryItems(ids: $libraryItemIds) {
      id
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading libraryItem dependencies.",
  collection: "libraryItems"
};

export const gqlLibraryItem : graphQLQuery = {
  query: `query
    libraryItems($itemId: [UUID!]) {
      libraryItems(ids: $itemId) {
        id
        item
        category
        contentType
        url
      }
  }`,
  errorMessage: "Error loading library items.",
  collection: "libraryItems"
};

export const gqlUpdateLibraryItem : graphQLQuery = {
  query: `mutation updateLibraryItem($id: UUID!, $input: UpdateLibraryItemInput!) {
    updateLibraryItem(id: $id, input: $input) {
      libraryItem {
        id
        item
        category
      }
    }
  }`,
  errorMessage: "Error loading library item.",
  collection: "updateLibraryItem.libraryItem"
};

export const gqlDeleteLibraryItem : graphQLQuery = {
  query: `mutation deleteLibraryItem($input: DeleteLibraryItemInput!) {
    deleteLibraryItem(input: $input) {
      libraryItem {
        id
      }
    }
  }`,
  errorMessage: "Error deleting libraryItem.",
  collection: "deleteLibraryItem.libraryItem"
};

export const gqlAddLibraryItem : graphQLQuery = {
  query: `mutation addLibraryItem($input: AddLibraryItemInput!) {
    addLibraryItem(input: $input) {
      libraryItem {
        id
        item
        category
      }
    }
  }`,
  errorMessage: "Error loading library item.",
  collection: "addLibraryItem.libraryItem"
};