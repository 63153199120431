/**
 * Function to add query parameters to a URL without query params.
 * @param baseUrl The base URL.
 * @param params Dictionary of query params to add to the URL. Values should *not* be URI encoded already, we do that in this method.
 * @returns The resulting URL after adding the query parameters to the base URL.
 */
export function addQueryParametersToUrl(
  baseUrl: string,
  params: { [key: string]: string }
): string {
  const encodedParams = Object.keys(params)
    .map((key: string) => {
      return key + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

  if (!encodedParams || encodedParams.length === 0) return baseUrl;

  return baseUrl + "?" + encodedParams;
}
