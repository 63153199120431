/**
 * Transforms a flat list of nodes into a tree hierarchy by using special selector functions.
 * @param nodes The list of nodes to transform into a tree.
 * @returns A tree hierarchy
 */
export function buildTree<T>(
    nodes: T[],
    getId: (node: T) => string,
    getParentId: (node: T) => string | null,
    getChildren: (node: T) => T[],
    setChildren: (node: T, children: T[]) => void
  ): T[] {
    const nodesById: { [key: string]: T } = {};
    const roots: T[] = [];

    // First pass: Create a map of all nodes by their IDs
    nodes.forEach((node) => {
      const id = getId(node);
      nodesById[id] = { ...node };
      setChildren(nodesById[id], []); // Initialize the children array
    });

    // Second pass: Establish parent-child relationships
    nodes.forEach((node) => {
      const id = getId(node);
      const parentId = getParentId(node);
      if (parentId !== null) {
        const parent = nodesById[parentId];
        if (parent) {
          setChildren(parent, [...getChildren(parent), nodesById[id]]);
        }
      } else {
        roots.push(nodesById[id]);
      }
    });

    return roots;
  }