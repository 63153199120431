import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DropDownBox } from "devextreme-react/drop-down-box";
import { TreeView } from "devextreme-react/tree-view";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { Validator, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import {
  gqlAddDevice,
  gqlDeleteDevice,
  gqlLocations,
  gqlQueryDevice,
  gqlQueryDeviceDependencies,
  gqlUpdateDevice,
} from "./DeviceQueries";
import { query, mutation } from "../../Services/GraphQL";
import { IDevice } from "./IDevice";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ILocation } from "./ILocation";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";

export const DevicePropertiesPilet: Pilet = {
  name: "Device Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension<"device-properties">(
      "device-properties",
      ({ params }) => {
        const emptyDevice = {
          id: "",
          name: "",
          externalId: "",
          model: "",
          deviceType: "",
          manufacturer: "",
          site: { id: "", name: "" },
          location: { id: "", name: "" },
          dependencies: [],
        };

        const [site, setSite] = useState<string>("");
        const [device, setDevice] = useState<IDevice>(emptyDevice);
        const [locations, setLocations] = useState<ILocation[]>([]);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [treeBoxOpen, setTreeBoxOpen] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        React.useEffect(() => {
          setSite(api.getData("currentSite"));
          refreshLocations();

          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedDevice") {
              await refreshData(value?.id);
            }

            if (name === "currentSite") {
              setSite(value);
              await refreshLocations();
            }
          });
        }, []);

        React.useEffect(() => {}, [device]);

        const refreshLocations = async () => {
          const result = await query<ILocation[]>(gqlLocations);
          setLocations(result);
        };

        const refreshData = async (deviceId) => {
          setEditMode(false);
          if (!deviceId) {
            setDevice(emptyDevice);
            return;
          }

          const result = await query<IDevice[]>(gqlQueryDevice, {
            deviceIds: [deviceId],
          });
          if (result.length ?? 0 > 0) {
            setDevice(result[0]);
          } else {
            setDevice(emptyDevice);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newDevice = { ...device };
            newDevice[field] = e.value;
            setDevice(newDevice);
          }
        };

        const deleteDevice = async () => {
          const result = await deleteEntityGeneric<IDevice>(
            api,
            device.id,
            "device",
            gqlQueryDeviceDependencies,
            gqlDeleteDevice
          );

          if (result === true) {
            setDevice(emptyDevice);
          }
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const deviceToSave = {
              name: device.name,
              externalId: device.externalId,
              model: device.model,
              deviceType: device.deviceType,
              manufacturer: device.manufacturer,
              locationId: device.location.id,
            };

            if (device.id === "*") {
              const result = await mutation<IDevice>(gqlAddDevice, {
                input: deviceToSave,
              });

              if (result) {
                setDevice(result);
                api.emit("refresh", { name: "device", value: result.id });
                setEditMode(!editMode);
              } else {
                api.showNotification("Error adding device", { type: "error" });
              }

              return;
            }

            const result = await mutation<IDevice>(gqlUpdateDevice, {
              id: device.id,
              input: deviceToSave,
            });
            if (result) {
              setDevice(result);
              api.emit("refresh", { name: "device", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating device", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const treeViewItemSelectionChanged = (e) => {
          var newDevice = { ...device };
          newDevice.location = {
            id: e.node.key,
            name: "",
          };
          setDevice(newDevice);
        };

        const treeViewOnContentReady = (e) => {
          if (device.location?.id) e.component.selectItem(device.location?.id);
          else e.component.unselectAll();
        };

        const onTreeItemClick = () => {
          setTreeBoxOpen(false);
        };

        const onAddClick = () => {
          setEditMode(true);
          setDevice({ ...emptyDevice, id: "*" });
        };

        if (device.id === "")
          return (
            <div>
              <div style={{ display: "flex" }}>
                {site !== "*" ? (
                  <>
                    <div style={{ flexGrow: 1 }}></div>
                    <div style={{ padding: "0.75rem 1rem" }}>
                      <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                        <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>
                        Add New
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>To add a device select a site.</div>
                )}
              </div>
            </div>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRow
                  editMode={editMode}
                  onCancel={() => {
                    if (device.id == "*") {
                      setDevice({ ...emptyDevice });
                    } else {
                      refreshData(device.id);
                    }

                    setEditMode(false);
                    setTreeBoxOpen(false);
                  }}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteDevice()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChanged={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={device.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="ExternalId"
                    onValueChanged={(e) => handleValueChanged(e, "externalId")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={device.externalId}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Model"
                    onValueChanged={(e) => handleValueChanged(e, "model")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={device.model}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Device type"
                    onValueChanged={(e) => handleValueChanged(e, "deviceType")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={device.deviceType}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Manufacturer"
                    onValueChanged={(e) => handleValueChanged(e, "manufacturer")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={device.manufacturer}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>

                <div style={formPaddingStyle}>
                  <DropDownBox
                    readOnly={!editMode}
                    dataSource={locations}
                    value={device.location?.id}
                    label="Location"
                    displayExpr="name"
                    labelMode="floating"
                    stylingMode="outlined"
                    valueExpr="id"
                    opened={treeBoxOpen}
                    onFocusIn={() => setTreeBoxOpen(true)}
                  >
                    {/* <TreeView dataSource={ locations.filter(l => l.site.id == device.site.id) } */}
                    <TreeView
                      dataSource={locations}
                      ref={(ref) => {
                        this.treeView = ref;
                      }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parentId"
                      selectionMode="single"
                      displayExpr="name"
                      selectByClick={true}
                      onContentReady={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
                    />
                    <Validator>
                      <RequiredRule message="Location is required" />
                    </Validator>
                  </DropDownBox>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "device", sortOrder: 1, name: "Device Properties", icon: InfoIcon }
    );
  },
};
