import { IDependency } from "./IDependency";

export interface ILocation {
  id: string;
  name: string;
  site: {
    id: string;
  };
  parentId: string;
  dependencies: IDependency[];
}

export interface ISelectableLocation extends ILocation {
  selected: boolean;
}

export interface ILocationTreeNode extends ISelectableLocation {
  children?: ILocationTreeNode[];
  isExpanded?: boolean;
}

export function updateNodeRecursively(selectedIds: string[], loc: ILocationTreeNode): ILocationTreeNode {
  if (loc.children && loc.children.length > 0) {
    loc.children = loc.children.map((x) => updateNodeRecursively(selectedIds, x));
  }

  const isSelected = selectedIds.includes(loc.id);
  const isExpanded = loc.children && loc.children.some((x) => x.selected || x.isExpanded);
  return { ...loc, selected: isSelected, isExpanded: isExpanded };
}
