import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import DeviceIcon from "../../assets/icons/ic_devices_24px.svg";
import Tabs from "devextreme-react/tabs";
import { Column } from "devextreme-react/data-grid";
import { gqlQueryPaginatedDevices } from "./DeviceQueries";
import DataGridWithPaging, { DataGridWithPagingRef } from "../../Components/DataGridWithPaging/DataGridWithPaging";
import { IDevice } from "./IDevice";

export const DevicePilet: Pilet = {
  name: "Device Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "Devices",
      () => (
        <Link to="/devices">
          <div>
            <div>
              <img src={DeviceIcon}></img>
            </div>
            <div>Devices</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 4, location: "/devices" }
    );

    api.registerTile(
      "Devices",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + DeviceIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/devices">Devices</Link>
              <br />
              Administration of devices.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    api.registerPage("/devices", ({ children }) => {
      const dataGridRef = React.useRef<DataGridWithPagingRef>();
      const [selectedDevice, setSelectedDevice] = React.useState<IDevice | null>();

      const refreshData = () => {
        dataGridRef.current.refresh();
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "device") {
            refreshData();
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
            api.setData("selectedDevice", null);
          }
        });
      }, []);

      const onRowSelected = (row) => {
        const selectedDeviceInRow = row.selectedRowsData.length === 0 ? null : row.currentSelectedRowKeys[0] as IDevice;

        setSelectedDevice(selectedDeviceInRow);
        api.setData("selectedDevice", selectedDeviceInRow);
      };

      const tabs = [
        {
          id: 0,
          text: "device",
          icon: DeviceIcon,
          content: "Device content",
        },
      ];

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ margin: "1rem 1rem 0 1rem", backgroundColor: "#FFF" }}>
              <Tabs style={{ marginLeft: "1rem" }} dataSource={tabs} width={300} selectedItem={tabs[0]}></Tabs>
            </div>
            <div style={{ display: "flex", flexGrow: 2, margin: "0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <DataGridWithPaging
                  ref={dataGridRef}
                  loadQuery={gqlQueryPaginatedDevices}
                  onSelectionChanged={onRowSelected}
                >
                  <Column name="Name" dataField="name" dataType="string" />
                  <Column caption="External ID" dataField="externalId" dataType="string" />
                  <Column caption="Model" dataField="model" dataType="string" />
                  <Column caption="Device Type" dataField="deviceType" dataType="string" />
                  <Column caption="Location" dataField="location.name" dataType="string" />
                </DataGridWithPaging>
              </div>
              <div className="extension-list">
                <api.Extension
                    name="extension-list"
                    params={{ extensions: ["device", `device-${selectedDevice?.deviceType}`] }}
                />
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
