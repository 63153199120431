import { graphQLQuery } from "../../Services/GraphQL";

export const gqlOrganizations : graphQLQuery = {
  query: `query {
    organizations {
      id
      name
      parentId
    }
  }`,
  errorMessage: "Error loading organizations.",
  collection: "organizations"
};

export const gqlOrganization : graphQLQuery = {
  query: `query organizations($organizationId: [UUID!]) {
    organizations(ids: $organizationId) {
      id
      parentId
      name
      description
      locations { id }
      sites { id }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "organizations"
};

export const gqlLocations: graphQLQuery = {
  query: `query locations {
    locations {
      id
      name
      site { id }
      parentId
    }
  }`,
  errorMessage: "Error loading locations.",
  collection: "locations",
};

export const gqlQueryOrganizationDependencies : graphQLQuery = {
  query: `query organizations($organizationIds: [UUID!]) {
    organizations(ids: $organizationIds) {
      id
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading organization dependencies.",
  collection: "organizations"
};

export const gqlDeleteOrganization : graphQLQuery = {
  query: `mutation deleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      organization {
        id
      }
    }
  }`,
  errorMessage: "Error deleting organization.",
  collection: "deleteOrganization.organization"
};

export const gqlUpdateOrganization : graphQLQuery = {
  query: `mutation updateOrganization($id: UUID!, $input: UpdateOrganizationInput!) {
    updateOrganization(id: $id, input: $input) {
      organization {
        id
        name
        description
        locations { id }
        sites { id }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "updateOrganization.organization"
};

export const gqlAddOrganization : graphQLQuery = {
  query: `mutation addOrganization($input: AddOrganizationInput!) {
    addOrganization(input: $input) {
      organization {
        id
        name
        description
        locations { id }
        sites { id }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "addOrganization.organization"
};

export const gqlRemoveOrganizationFromSite : graphQLQuery = {
  query: `mutation RemoveOrganizationFromSite($input: SiteInput!) {
    removeOrganizationFromSite(input: $input) {
      organization {
        id
        name
        description
        locations { id }
        sites { id }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "removeSiteFromOrganization.organization"
};

export const gqlAddOrganizationToSite : graphQLQuery = {
  query: `mutation AddOrganizationToSite($input: SiteInput!) {
    addOrganizationToSite(input: $input) {
      organization {
        id
        name
        description
        locations { id }
        sites { id }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "addOrganizationToSite.organization"
};

export const gqlOrganizationIdentities : graphQLQuery = {
  query: `query organizations($organizationId: [UUID!]) {
    organizations(ids: $organizationId) {
      identities {
        system
        value
      }
    }
  }`,  
  errorMessage: "Error loading organization.",
  collection: "organizations"
};

export const gqlOrganizationIdentitiesConcurrencyCheck : graphQLQuery = {
  query: `query organizations($identity: IdentityFilter) {
    organizations(identities: $identity) {
      id
      identities {
        system
        value
      }
    }
  }`,  
  errorMessage: "Error loading organization.",
  collection: "organizations"
};

export const gqlAddOrganizationIdentity : graphQLQuery = { 
  query: `mutation addOrganizationIdentity($input: AddIdentityInput!) {
    addOrganizationIdentity(input: $input) {
      organization {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "addOrganizationIdentity.organization"
};

export const gqlRemoveOrganizationIdentity : graphQLQuery = { 
  query: `mutation removeOrganizationIdentity($input: RemoveIdentityInput!) {
    removeOrganizationIdentity(input: $input) {
      organization {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading organization.",
  collection: "removeOrganizationIdentity.organization"
};

export const gqlOrganizationSystems : graphQLQuery = {
  query: `query {
    organizationIdentitySystems { system }
  }`,  
  errorMessage: "Error loading organization systems.",
  collection: "organizationIdentitySystems"
};