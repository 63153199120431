import React, { forwardRef, useImperativeHandle, useState } from "react";
import DataGrid, { Paging, Pager } from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid from "devextreme/ui/data_grid";
import DataSource from "devextreme/data/data_source";
import { isNullOrUndefined } from "../../util/nullUtilities";
import { graphQLQuery, query } from "../../Services/GraphQL";
import { IPaginatedConnection } from "./IPaginatedConnection";

interface DataGridWithPagingProps extends React.RefAttributes<DataGridWithPagingRef> {
  /**
   * Method called when a row is selected.
   */
  onSelectionChanged: (e: EventInfo<dxDataGrid>) => void;

  /**
   * Children props passed to the DataGrid, e.g. a bunch of Columns.
   */
  children: React.ReactNode;

  /**
   * The graphQLquery object used to load data.
   */
  loadQuery: graphQLQuery;
}

export interface DataGridWithPagingRef {
  refresh: () => void;
}

/**
 * A wrapper for DataGrid with paging controls.
 * All you need to do is supply a graphQLQuery for loading data which supports pagination.
 */
const DataGridWithPaging: React.FC<DataGridWithPagingProps> = forwardRef<
  DataGridWithPagingRef,
  DataGridWithPagingProps
>(({ onSelectionChanged, loadQuery, children }, ref) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [items, setItems] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      console.debug("Refreshing data.");
      refreshData();
    },
  }));

  const refreshData = async () => {
    const afterIndex = pageIndex * pageSize - 1;
    const afterIndexBase64 = btoa(afterIndex.toString());
    console.debug("Refreshing data, pageIndex:", pageIndex, "pageSize:", pageSize, "after", afterIndex);
    const result = await query<IPaginatedConnection>(loadQuery, {
      first: pageSize,
      after: afterIndexBase64,
    });

    if (isNullOrUndefined(result)) {
      console.error("No paginated items:", result);
      return;
    }

    // We add fake entries to result.nodes so that the data grid component shows the "right" entries that we just fetched.
    // Insert fake entries before the data which is displayed.
    for (let i = 0; i <= afterIndex; i++) {
      result.nodes.unshift(null);
    }

    // Insert fake entries "after" the displayed data so that the total count is still correct.
    const toAdd = result.totalCount - result.nodes.length;
    for (let i = 0; i < toAdd; i++) {
      result.nodes.push(null);
    }
    setItems(result.nodes);
  };

  React.useEffect(() => {
    refreshData();
  }, [pageIndex, pageSize]);

  const handleOptionChange = (e): void => {
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value);
    } else if (e.fullName === "paging.pageSize") {
      setPageIndex(0); // Also reset page index to prevent funky situations.
      setPageSize(e.value);
    }
  };

  const dataSource = new DataSource(items);

  return (
    <DataGrid
      onSelectionChanged={onSelectionChanged}
      onOptionChanged={handleOptionChange}
      selection={{ mode: "single" }}
      dataSource={dataSource}
      style={{ height: "calc(100vh - 220px)" }}
      showBorders={true}
    >
      <Paging pageIndex={pageIndex} pageSize={pageSize} defaultPageSize={10} />
      <Pager
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={[5, 10, 20]}
        showInfo={true}
        showNavigationButtons={true}
      />
      {children}
    </DataGrid>
  );
});

export default DataGridWithPaging;
