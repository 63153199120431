import { TextBox } from 'devextreme-react/text-box';
import * as React from 'react';

function colorTranslation(color: string): string {
  switch(color) {
    case "BURGUNDY":
      return "#4C1C24";
    case "GREY":
      return "GRAY";
    default: 
      return color;
  }
}

export function Color(data) {
  if (!data) return (<></>);

  return (
    <><span style={{backgroundColor: colorTranslation(data.id), border: "1px solid #CCC", width: "25px", display: "inline-block", borderRadius: "4px"}}>&nbsp;</span>&nbsp;<span>{data.name}</span></>
  );
}

export function ColorField(data) {
  if (!data) return (<><TextBox></TextBox></>);

  return (
    <>
      <span style={{backgroundColor: colorTranslation(data.id), marginLeft: "18px", maxWidth: "25px", border: "1px solid #CCC", display: "inline-block", borderRadius: "4px"}}>&nbsp;</span>
      <TextBox defaultValue={data && data.name} readOnly={true} />
    </>
  );
}