import { TextBox } from 'devextreme-react/text-box';
import * as React from 'react';
import SoundIcon from '../../assets/icons/sound.jpg';

const customItem: React.CSSProperties = {
  display: "inline-block",
  marginLeft: "18px",
  textIndent: "0",
  lineHeight: "30px",
  maxWidth: "25px",
};

const customImage: React.CSSProperties = {
  width: "25px",
  height: "25px",
  marginTop: "-12px",
  position: "absolute",
  top: "50%"
};

const imageUrl = (e) => {
  if (e != undefined) return e + "&width=25";

  return e;
}

export function Icon(data) {
  if (!data) return (<></>);

  const image = data.contentType.indexOf("image")===0 ? imageUrl(data.url) : SoundIcon;
  return (
    <><span style={{display: "inline-block"}}><img style={{width: "25px", height: "25px"}} src={image}/></span><span style={{display: "inline-block", verticalAlign: "top", marginTop: "5px", marginLeft: "10px"}}>{data.category}:{data.item}</span></>
  );
}

export function IconField(data) {
  if (!data) return (<><TextBox></TextBox></>);
  
  const image = data.contentType.indexOf("image")===0 ? imageUrl(data.url) : SoundIcon;
  return (
    <>
      <div style={customItem}><img style={customImage} src={image}/></div>
      <div style={{display: "inline-block", verticalAlign: "top", marginTop: "5px"}}>
        <TextBox defaultValue={data.category + ":" + data.item} readOnly={true} />
      </div>
    </>
  );
}