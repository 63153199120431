import { PiletApi } from "piral-base";

export interface IIdentity { 
  system: string;
  value: string;
  operation: IdentityOperation;
}

export enum IdentityOperation {
  Add,
  Remove,
  None,
}

export interface IAddIdentitiesModalOptions {
  addIdentity: (identity: IIdentity) => boolean;
  onConcurrencyError?: (identity: IIdentity) => Promise<boolean>;
  systems: string[];
}

export interface IdentityListProps {
  systems: string[];
  identities: IIdentity[];
  onSave?: (identities: IIdentity[]) => void;
  onCancel?: () => void;
  onConcurrencyError?: (identity: IIdentity) => Promise<boolean>;
  api?: PiletApi;
}