import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import {
  gqlAddLibraryItem,
  gqlDeleteLibraryItem,
  gqlLibraryItem,
  gqlQueryLibraryItemDependencies,
  gqlUpdateLibraryItem,
} from "./LibraryItemQuery";

import { FileUploader, Button, TextBox, Validator } from "devextreme-react";
import { RequiredRule, StringLengthRule } from "devextreme-react/validator";
import EditIcon from "../../assets/icons/ic_edit_24px.svg";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { getApiRealBaseUrl } from "../../Services/config";
import { ILibraryItem } from "./ILibraryItem";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";

export const LibraryItemPropertiesPilet: Pilet = {
  name: "Library Items Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "libraryitems-properties",
      ({ params }) => {
        const emptyLibraryItem: ILibraryItem = {
          id: "",
          item: "",
          category: "",
          contentType: "",
          url: "",
          dependencies: [],
        };

        const [libraryItem, setLibraryItem] = useState<ILibraryItem>(emptyLibraryItem);
        const [editMode, setEditMode] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedLibraryItem" && value != "") {
              await refreshData(value);
              console.log(`New value is "${value}"!`);
            }
          });
        }, []);

        const refreshData = async (libraryItemId) => {
          setEditMode(false);
          try {
            const result = await query<ILibraryItem[]>(gqlLibraryItem, { itemId: [libraryItemId] });
            if (result) setLibraryItem(result[0]);
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const libraryitemToSave = {
              category: libraryItem.category,
              item: libraryItem.item,
            };

            if (libraryItem.id === "*") {
              const result = await mutation<ILibraryItem>(gqlAddLibraryItem, { input: libraryitemToSave });

              if (result) {
                setLibraryItem(result);
                api.emit("refresh", { name: "libraryItem", value: result.id });
                setEditMode(false);
              } else {
                api.showNotification("Error adding libraryitem", { type: "error" });
              }

              return;
            }

            const result = await mutation<ILibraryItem>(gqlUpdateLibraryItem, {
              id: libraryItem.id,
              input: libraryitemToSave,
            });

            if (result) {
              setLibraryItem(result);
              api.emit("refresh", { name: "libraryItem", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating libraryitem", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newLibraryItem = { ...libraryItem };
            newLibraryItem[field] = e.value;
            setLibraryItem(newLibraryItem);
          }
        };

        const deleteLibraryItem = async () => {
          const result = await deleteEntityGeneric<ILibraryItem>(
            api,
            libraryItem.id,
            "libraryItem",
            gqlQueryLibraryItemDependencies,
            gqlDeleteLibraryItem
          );

          if (result === true) {
            setLibraryItem(emptyLibraryItem);
          }
        };

        if (libraryItem.id === "") return <>No selected library item</>;

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <div style={{ padding: "0.75rem 1rem" }}>
                  <Button
                    visible={editMode}
                    width={120}
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => {
                      refreshData(libraryItem.id);
                      setEditMode(false);
                    }}
                  >
                    <span className="dx-icon-close" style={{ fontSize: "18px" }}></span>Cancel
                  </Button>
                  &nbsp;
                  <Button
                    visible={!editMode}
                    width={120}
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => {
                      setEditMode(true);
                      setLibraryItem({ ...emptyLibraryItem, id: "*" });
                    }}
                  >
                    <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>Add root
                  </Button>
                  &nbsp;
                  <Button
                    visible={!editMode}
                    width={120}
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => setEditMode(true)}
                  >
                    <img src={EditIcon} style={{ width: "18px" }} />
                    Edit
                  </Button>
                  <Button
                    visible={!editMode}
                    width={120}
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => deleteLibraryItem()}
                  >
                    <span className="dx-icon-trash" style={{ fontSize: "18px" }}></span>Delete
                  </Button>
                  &nbsp;
                  <Button
                    visible={editMode}
                    width={120}
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={updateEntity}
                  >
                    <span className="dx-icon-floppy" style={{ fontSize: "18px" }}></span>Save
                  </Button>
                </div>
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Item"
                    onValueChanged={(e) => handleValueChanged(e, "item")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={libraryItem.item}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={40} message="Max 40 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Category"
                    onValueChanged={(e) => handleValueChanged(e, "category")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={libraryItem.category}
                  >
                    <Validator>
                      <StringLengthRule max={5000} message="Max 5000 characters" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "libraryItem", sortOrder: 1, name: "Library item properties", icon: InfoIcon }
    );

    api.registerExtension(
      "libraryitem-upload",
      ({ params }) => {
        const onSelectedFilesChanged = () => {
          api.emit("refresh", { name: "libraryItem" });
        };

        return (
          <>
            <FileUploader
              uploadMethod="POST"
              uploadHeaders={{ authorization: "Bearer " + api.getData("accessToken") }}
              multiple={false}
              accept=".zip"
              uploadMode="instantly"
              uploadUrl={getApiRealBaseUrl() + "/api/LibraryItems"}
              onFilesUploaded={onSelectedFilesChanged}
              width={430}
            />
          </>
        );
      },
      { type: "libraryItem", sortOrder: 2, name: "Upload library items" }
    );
  },
};
