import * as React from 'react';
import { Pilet, PiletApi } from 'piral-core';
import { Link } from 'react-router-dom';
import UserIcon from '../../assets/icons/ic_user_24px.svg';
import { gqlQueryPaginatedUsers } from './UserQuery';
import { Column } from 'devextreme-react/data-grid';
import Tabs from 'devextreme-react/tabs';
import DataGridWithPaging, { DataGridWithPagingRef } from '../../Components/DataGridWithPaging/DataGridWithPaging';

export const UserPilet: Pilet = {
  name: 'User Module',
  version: '1.0.0',
  spec: 'v2',
  dependencies: {},
  config: {},
  basePath: '/pilets',
  link: '/pilets/connector',
  setup(api: PiletApi) {
    api.registerMenu("Users",
      () => (
        <Link to="/users">
          <div>
            <div><img src={UserIcon}></img></div>
            <div>Users</div>
          </div>
        </Link>
      ),
      { type: 'general' , sortOrder: 3, location: "/users" },
    );

    api.registerTile("Users", () => {
      const smallTileStyle: React.CSSProperties = {
        backgroundImage: 'url(' + UserIcon + ')',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#d8d8d8",
        backgroundSize: "cover",
        borderRadius: "0.5rem",
        verticalAlign: "middle",
        textAlign: "center",
        paddingTop: "40px"
      };
      return (
        <div className="tile">
          <div style={smallTileStyle}>
            <Link to="/users">Users</Link>
            <br />
            Administration of users.
          </div>
        </div>
      );
    }, { initialColumns: 2, initialRows: 2 });

    api.registerPage('/users', ({ children }) => {
      const dataGridRef = React.useRef<DataGridWithPagingRef>();

      const refreshData = async () => {
        dataGridRef.current.refresh();
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on('refresh', data => {
          if (data.name === 'user') {
            refreshData();
          }
        });

        api.on('store-data', ({ name, value }) => {
          if (name === 'currentSite') {
            console.log("DataChanged name:" + name + " value:" + value);
            api.setData('selectedUser', '');
            refreshData();
          }
        });
      }, [])

      const imageUrl = (e) => {
        if (e != undefined) return e + "&width=30"

        return e;
      }

      const onRowSelected = (row) => {
        api.setData('selectedUser', row.selectedRowsData.length === 0 ? '' : row.currentSelectedRowKeys[0].id);
      };

      const tabs = [
        {
          id: 0,
          text: 'Users',
          icon: UserIcon,
          content: 'Users content'
        },
      ];

      const renderAvatar = (e) => {
        return (<><img style={{borderRadius: "50%"}} src={imageUrl(e.data.avatar?.url)}/></>);
      }

      return (
        <>
        <div style={{display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem"}}>
          <div style={{margin: "1rem 1rem 0 1rem", backgroundColor: "#FFF"}}><Tabs style={{marginLeft: "1rem"}} dataSource={tabs} width={300} selectedItem={tabs[0]}></Tabs></div>
          <div style={{display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF"}}>
            <div className="list-items-container">
            <DataGridWithPaging
              onSelectionChanged={onRowSelected}
              loadQuery={gqlQueryPaginatedUsers}
              ref={dataGridRef}
            >
              <Column
                name='First name'
                dataField="firstName"
                dataType="string"
              />
              <Column
                caption='Last name'
                dataField="lastName"
                dataType="string"
              />
              <Column
                caption='Initials'
                dataField="initials"
                dataType="string"
              />
              <Column
                caption='Middle name'
                dataField="middleName"
                dataType="string"
              />
              <Column
                caption='Email'
                dataField="email"
                dataType="string"
              />
              <Column
                caption='Avatar'
                cellRender={renderAvatar}
              />
            </DataGridWithPaging>
            </div>
            <div className="extension-list">
              <api.Extension name="extension-list" params={{extensions: "user"}}></api.Extension>
            </div>
          </div>
          </div>
        </>
      )
    });
  }
};