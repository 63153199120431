import * as React from 'react';
import { client, instance } from '../../index';
import { useState } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { Site, SiteField} from './Site';
import { delay } from '../../util/delay';

export const SiteSelector = () => {
  const [site, setSite] = useState('*');
  const [sites, setSites] = useState([]);

  const siteSelectorStyle: React.CSSProperties = {
    height: "2.25rem",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.25rem",
    letterSpacing: "0.04rem",
    textTransform: "uppercase",
    border: "1px solid rgba(23, 53, 68, 0.60)",
    borderRadius: "0.25rem",
    width: "11.375rem"
  };

  const gql = `query {
    sites {
      id
      name
    }
  }`;

  React.useEffect(() => {
    instance.root.setData("currentSite", "*");

    instance.root.on('refresh', data => { 
      if (data.name === 'site' || data.name === "tenant") {
        console.log('Refresh on: ' + data.name)
        refreshData();
      }
    });

    //refreshData();
  }, [])

  const refreshData = async () => {
    await delay(200);

    const result = await client.query(gql).toPromise();
    setSites([{id: "*", name: "ALL SITES"}, ...result.data.sites]);
    setSite('*');
    instance.root.setData("currentSite", "*");
  };

  const handleClick=(id) => {
    console.log("Set data: " + id);
    setSite(id);
    instance.root.setData("currentSite", id);
  };

  return (
    <SelectBox style={{ height: "35px" }} value={site} items={sites} itemRender={Site} fieldRender={SiteField} displayExpr="name" valueExpr="id" stylingMode="outlined" label='Site' defaultValue={"*"} onValueChange={handleClick}></SelectBox>
  );
};