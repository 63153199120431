import * as React from 'react';
import { Pilet, PiletApi, useGlobalState } from 'piral-core';
import { Link } from 'react-router-dom';
import OrganizationIcon from '../assets/icons/ic_my_units_24px.svg'; 
import LocationIcon from '../assets/icons/ic_locations_buildings_24px.svg'; 
import UserIcon from '../assets/icons/ic_user_24px.svg'; 

/**
 * Shows the general usage of the `setup` function together
 * with some tile and page registrations.
 * Also registeres some custom error page handlers. For details
 * on this, see DashboardModule.
 */
export const TestPilets: Pilet = {
  name: 'Example Module',
  version: '1.0.0',
  spec: 'v2',
  dependencies: {},
  config: {},
  basePath: '/pilets',
  link: '/pilets/connector',
  setup(api: PiletApi) {
    api.registerPage('/demo2', ({ children }) => {
      const tiles = useGlobalState((s) => s.registry.extensions);

      return (
        <>
          <h1>Available Templates</h1>
          {Object.keys(tiles).map((tile) => {console.log(tile); return (<div key={tile}>{tile}<api.Extension name={tile}></api.Extension></div>)})} 
        </>
      );
    });

    api.registerModal("mymodal", ({ onClose }) => { 
      const gql = `query {
        sites {
          id
          name
        }
      }`;

      const data = api.query(gql, ).then(({ id, name }) => {
        console.log(id, name)
      });

      React.useEffect(() => {
        console.log("Use Effects");
        data;
      }, [])
      
      return(
        <div className='simpleModal'>
           {  }
          <div>This is a simple modal</div>
          <button onClick={onClose}> Close</button>
        </div>
      );
    });

    api.registerPage('/modaldemo', ({ children }) => (
      <div>
        <p>
          This is an example for a <b>modal</b>
        </p>
        <p>Click to show a modal</p>
        <ul>
          <li>
            <button onClick={() => api.showModal('mymodal', { Value: 42 })}>Show Modal</button>
          </li>
        </ul>
      </div>
    ));

    api.registerPage('/notificationdemo', ({ children }) => (
      <div>
        <p>
          This is the first <b>example</b> page
        </p>
        <p>Click for a notification.</p>
        <ul>
          <li>
            <button onClick={() => api.showNotification('Hello there!')}>Notify me! (Default)</button>
          </li>
          <li>
            <button onClick={() => api.showNotification('Hello there!', { type: 'error' })}>
              Notify me! (Error)
            </button>
          </li>
          <li>
            <button onClick={() => api.showNotification('Hello there!', { title: 'Some title' })}>
              Notify me! (With Title)
            </button>
          </li>
          <li>
            <button onClick={() => api.showNotification('Hello there!', { autoClose: 1000, type: 'success' })}>
              Notify me! (1s)
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                api.showNotification(
                  <span>
                    Hello there; this is <b>some longer text</b>!
                  </span>,
                  { autoClose: 1500, type: 'warning' },
                )
              }>
              Notify me! (longer, formatted text 1.5s)
            </button>
          </li>
        </ul>
      </div>
    ));

    api.registerPage('/locations', ({ piral }) => (
      <div>
        <p>
          This is the second <b>example</b> page
        </p>
        <p>
          IF YOU ARE IN AN ADVENTUROUS MOOD TRY{' '}
          <a
            onClick={(e) => {
              api.unregisterMenu("example2");
              api.unregisterPage('/example2');
              e.preventDefault();
            }}
            href="#">
            THIS LINK
          </a>
          .
          <a className="waves-effect waves-light btn">button</a>
        </p>
        <api.Extension
          name="react-extension"
          params={{ value: 5 }}
        />
        <api.Extension
          name="react-extension"
          params={{ value: 3 }}
        />
        <api.Extension
          name="react-extension"
        />

        <api.Extension
          name="sample-extension"
          params={{ Test: 3 }}
        />
      </div>
    ));

    api.registerExtension('error', () => <div>Custom Error page</div>);

    api.registerExtension('react-extension', ({ params }) => {
      const [counter, setCounter] = React.useState(0);

      api.on('store-data', ({ name, value }) => {
        if (name === 'counter-data') {
          console.log(`New value is "${value}"!`);
          setCounter(value);
        }

        if (name === 'blazor-counter-data') {
          console.log(`New blazor value is "${value}"!`);
          api.setData('counter-data', value);
        }
      });

      const handleClick = (e) => {
        api.setData('counter-data', counter + 1); 
      };

      const initialValue = () => {
        if (params.value) return params.value;

        return 0;
      };

      React.useEffect(() => {
        if (api.getData("counter-data")) { 
          setCounter(api.getData("counter-data"));
        }
      }, []);
      
      return (
        <div>
          <div>
            Hello from react, the initial value was {initialValue()} and the counter is: {counter + initialValue() ?? 0}
          </div>
          <div><button onClick={handleClick}>Increment</button></div>
        </div>
      )
    }, { type: 'user2' , sortOrder: 100, name: "Counter demo" });

    api.registerExtension('error', ({ params }) => {
      if (params.type === 'not_found') {
        return <div>The page was not found!!!</div>;
      }
      return false as any;
    });
  },
};