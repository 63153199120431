import * as React from 'react';
import AscomLogo from '../../assets/icons/logo_Ascom.svg';
import { instance } from '../../index';

export const Footer = () => {
  const rootApi = instance.root;

  return (
    <footer>
        <div className="footer-container">
          <div><img src={AscomLogo} height={10}/></div>
          <div className='about-label' onClick={() => rootApi.showModal("about-modal")}>
          About
          </div>
        </div>
    </footer>
  );
};