import { generateCodeVerifier, generateCodeChallengeFromVerifier } from "./crypto";
import { addQueryParametersToUrl } from "../util/urlUtilities";
import { getConfig } from "./config";
import { PiralInstance } from "piral";
import { jwtDecode } from "jwt-decode";

let identityProviderUri = "http://localhost:8080/realms/daniel/protocol/openid-connect";
let authUri = `${identityProviderUri}/auth`;
let userUri = `${identityProviderUri}/userinfo`;
let tokenUri = `${identityProviderUri}/token`;
let logoutUri = `${identityProviderUri}/logout`;
let clientId = "aup";
let redirectUri = `${location.origin}/auth`;
let logoutRedirectUrl = `${location.origin}/`;
let client_secret = "BAjYhzVLPHyHIqvaah8x5IlU2JrGZsy9";
let scope = "openid";

let currentPromise = Promise.resolve();
let refreshTokenTimer = null;

interface TokenData {
  id_token: string;
  id_token_expires_in: number;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
}

export interface User {
  sub: string;
  preferred_username: string;
  DOB: string;
  organization: string;
}

export enum UserLoginResult {
  LoginFailed,
  LoginOkAuthorizationFailed,
  Ok
}

function buildQueryString(params: Record<string, string>) {
  return Object.entries(params)
    .map(([name, value]) => `${name}=${value}`)
    .join("&");
}

/**
 * @param seconds Number of seconds to add to Date.now()
 * @returns The current timestamp (Date.now()) plus the given number of seconds, as a string.
 */
function getExpirationTimestamp(seconds: number): string {
  return `${seconds * 1000 + Date.now()}`;
}

function setTokenData(c: TokenData) {
  if (
    typeof c.access_token !== "string" ||
    typeof c.refresh_token !== "string" ||
    typeof c.expires_in !== "number" ||
    typeof c.refresh_expires_in !== "number"
  ) {
    throw new Error("Invalid token data received.");
  }

  console.debug("Token updated. Expires in:", c.expires_in);
  sessionStorage.setItem("access_token", c.access_token);
  sessionStorage.setItem("id_token", c.id_token);
  sessionStorage.setItem("refresh_token", c.refresh_token);
  sessionStorage.setItem("expires_in", getExpirationTimestamp(c.expires_in));
  sessionStorage.setItem("refresh_expires_in", getExpirationTimestamp(c.refresh_expires_in));
}

export function setupRefreshTokenTimer(app: PiralInstance) {
  var msBeforeRefresh = getMsUntilNextRefreshToken();

  console.log("Refreshing token in", msBeforeRefresh / 1000, "s", "expires in s:", msUntilTokenExpires() / 1000);
  refreshTokenTimer = setInterval(refreshToken, msBeforeRefresh, app);
}

/**
 * Returns the amount of ms we should wait before we refresh the token.
 * This will be the amount of time the current token is valid for, minus a buffer.
 * @returns The amount of milliseconds we should wait before we refresh the token.
 */
function getMsUntilNextRefreshToken() {
  var tokenExpirationMs = msUntilTokenExpires();

  // If token expires in more than 10 minutes, refresh in 5 minutes before it expires.
  // else if token expires in less than 10 minutes, refresh token 5 seconds before it expires.
  const msUntilTokenExpiresMinusBuffer =
    tokenExpirationMs > 10 * 60000 ? tokenExpirationMs - 5 * 60000 : tokenExpirationMs - 5000;

  var minSafeTimeToRefresh = Math.max(0, msUntilTokenExpiresMinusBuffer);
  const thirtyMinutesAsMs = 30 * 60 * 1000;

  // Do not wait more than 30 minutes before refreshing the token.
  return Math.min(minSafeTimeToRefresh, thirtyMinutesAsMs);
}

function resetTokenData() {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("expires_in");
  sessionStorage.removeItem("refresh_expires_in");
  sessionStorage.removeItem("verifier");
}

function updateToken() {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: buildQueryString({
      grant_type: "refresh_token",
      client_id: clientId,
      refresh_token: sessionStorage.getItem("refresh_token"),
      client_secret: client_secret,
    }),
  };

  console.debug("Refreshing token now.");

  const promise = fetch(tokenUri, data)
    .then((res) => {
      if (res.status === 200) {
        return res.json().then(setTokenData);
      }

      return Promise.reject(res.json());
    })
    .catch((err) => {
      console.error("Error during token update", err);
      resetTokenData();
      return Promise.reject(err);
    });

  currentPromise = promise;

  return promise;
}

export function setupClient() {
  const config = getConfig();
  console.log("Setting up client");
  identityProviderUri = config.identityProviderUri;
  authUri = `${identityProviderUri}/auth`;
  userUri = `${identityProviderUri}/userinfo`;
  tokenUri = `${identityProviderUri}/token`;
  logoutUri = `${identityProviderUri}/logout`;
  clientId = config.clientId;
  redirectUri = `${location.origin}/auth`;
  logoutRedirectUrl = `${location.origin}/`;
  client_secret = config.client_secret;
  scope = "openid";
}

export function getUserInfo(): Promise<User> {
  return getToken()
    .then((token) =>
      fetch(userUri, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
    )
    .then((res) => res.json());
}

export function login() {
  const verifier = generateCodeVerifier();
  sessionStorage.setItem("verifier", verifier);

  currentPromise = generateCodeChallengeFromVerifier(verifier)
    .then((challenge) =>
      buildQueryString({
        client_id: clientId,
        redirect_uri: redirectUri,
        client_secret: client_secret, // not needed
        scope: scope,
        response_type: "code",
        code_challenge: challenge,
        code_challenge_method: "S256",
      })
    )
    .then((query) => {
      location.href = `${authUri}?${query}`;
    });
}

export function getToken(): Promise<string> {
  return currentPromise.then(() => {
    const token = sessionStorage.getItem("access_token");

    if (token !== null) {
      const expires = +sessionStorage.getItem("expires_in");

      if (Date.now() > expires) {
        updateToken();
        return getToken();
      }
    }

    return token;
  });
}

export function getStoredToken(): string {
  return sessionStorage.getItem("access_token");
}

/**
 * @returns The amount of milliseconds the token is still valid for.
 */
export function msUntilTokenExpires(): number {
  const token = sessionStorage.getItem("access_token");

  if (token !== null) {
    const expiresAt = +sessionStorage.getItem("expires_in");
    return expiresAt - Date.now();
  }

  return 0;
}

function msUntilRefreshTokenExpires(): number {
  const token = sessionStorage.getItem("access_token");

  if (token !== null) {
    const expiresAt = +sessionStorage.getItem("refresh_expires_in");
    const msUntilRefreshTokenExpires = expiresAt - Date.now();
    console.debug("Refresh token expires in", msUntilRefreshTokenExpires / 1000, "s");
    return msUntilRefreshTokenExpires;
  }

  return 0;
}

let refreshTokenFailures = 0;
export async function refreshToken(app: PiralInstance) {
  if (msUntilRefreshTokenExpires() <= 1000) {
    console.log("Refresh token is expiring; logging out.");
    resetTokenData();
    location.href = redirectUri;
    return;
  }

  clearInterval(refreshTokenTimer);

  updateToken()
    .then(async () => {
      // We succeeded in refreshing the token. Refresh it again 5 minutes before it expires.
      const msUntilNextRefresh = getMsUntilNextRefreshToken();
      refreshTokenFailures = 0;
      refreshTokenTimer = setInterval(refreshToken, msUntilNextRefresh);

      app.root.setData("accessToken", await getToken());

      console.log("Token refreshed. Next refresh happens in", msUntilNextRefresh / 1000, "s");
    })
    .catch(() => {
      // We failed to refresh the token. Try again in 10 seconds.
      refreshTokenFailures++;
      console.warn("refreshToken failed calls: " + refreshTokenFailures);
      refreshTokenTimer = setInterval(refreshToken, 10000);
    });
}


function getLoginResultFromAccessToken(accessToken: string) : UserLoginResult {
  if (!accessToken) {
    return UserLoginResult.LoginFailed;
  }

  let isAuthenticated = false;
  try {
    const decodedToken = jwtDecode(accessToken) as any;
    isAuthenticated = decodedToken.realm_access.roles.some(roleName => roleName == "AscomAdmin");
  } catch (error) {
    console.debug(`Error reading access rights: ${error?.message}`)
    return UserLoginResult.LoginOkAuthorizationFailed;
  }

  return isAuthenticated ? UserLoginResult.Ok : UserLoginResult.LoginOkAuthorizationFailed
}

export function isLoggedIn(): Promise<UserLoginResult> {
  return getToken().then(
    (token) => getLoginResultFromAccessToken(token),
    () => UserLoginResult.LoginFailed
  );
}

export function logout() {
  const queryParams = {
    id_token_hint: sessionStorage.getItem("id_token"),
    post_logout_redirect_uri: logoutRedirectUrl,
  };

  resetTokenData();
  location.href = addQueryParametersToUrl(logoutUri, queryParams);
}

export function initOIDC() {
  console.log("OIDC Async start");
  setupClient();

  if (location.pathname === "/auth") {
    console.log("OIDC path is auth");
    const verifier = sessionStorage.getItem("verifier");
    const queryParams = Object.fromEntries(
      location.search
        .substring(1)
        .split("&")
        .map((s) => s.split("="))
    );

    if (verifier && queryParams.code) {
      const data = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: buildQueryString({
          grant_type: "authorization_code",
          client_id: clientId,
          scope: scope,
          client_secret: client_secret,
          code: queryParams.code,
          redirect_uri: redirectUri,
          code_verifier: verifier,
        }),
      };

      currentPromise = fetch(tokenUri, data)
        .then((res) => {
          console.log(`OIDC Fetch token: ${res.status}`);
          if (res.status === 200) {
            return res.json().then(setTokenData);
          }

          return Promise.reject(res.json());
        })
        .catch((err) => {
          console.error("OIDC Error during token retrieval", err);
          resetTokenData();
        });
    }
  }
}
