import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import Logo from "../../assets/icons/AscomSharedAdmin_Logo_36px.svg";
import { Link } from "react-router-dom";

export const AboutModal: Pilet = {
  name: "About Modal Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerModal("about-modal", ({ onClose }) => (
      <div className="modal-container">
        <div className="modal" style={{height: 250}}>
          <div className="modal-header">
            <div className="modal-header-text">About</div>
            <div className="modal-header-right">
              <a
                className="modal-button-close"
                onClick={onClose}
                data-testid="aboutCloseButton"
              ></a>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-body-left">
              <div className="assign-logo">
                <img className="assign-logo" src={Logo} />
              </div>
              <div className="assign-text">Ascom Admin</div>
              <div className="assign-version" data-testid="aboutVersion">
                Version: V{require("../../../package.json").version}
              </div>
              <div className="assign-version" data-testid="aboutSoftwareNumber">
                SW001061
              </div>
              <div
                className="assign-version licenses"
                data-testid="aboutCopyright"
              >
                © 2023 Ascom
              </div>
              <div
                className="assign-version"
                data-testid="aboutAllRightsReserved"
              >
                All rights reserved
              </div>
              <div
                className="licenses assign-version"
                data-testid="licensesLink"
              >
                <Link to="licenses" onClick={onClose}>
                  Licenses
                </Link>
              </div>
            </div>
            <div className="modal-body-right">
              <div className="modal-body-right-inner">
                <div className="ascom-logo"></div>
                <div className="ascom-address">
                  <div data-testid="aboutAddress-1">Ascom (Sweden) AB</div>
                  <div className="clear-left" data-testid="aboutAddress-2">
                    Grimbodalen 2
                  </div>
                  <div className="clear-left" data-testid="aboutAddress-3">
                    SE-417 49 Göteborg
                  </div>
                  <div data-testid="aboutAddress-4">SWEDEN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  },
};
