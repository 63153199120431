import { Pilet, PiletApi } from "piral-base";
import React, { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import IdentityIcon from "../../assets/icons/Group.svg";
import { gqlAddUserIdentity, gqlRemoveUserIdentity, gqlUserIdentities, gqlUserIdentitiesConcurrencyCheck, gqlUserSystems } from "../Users/UserQuery";
import Button from "devextreme-react/button";
import EditIcon from '../../assets/icons/ic_edit_24px.svg'; 
import { IAddIdentitiesModalOptions, IIdentity, IdentityListProps, IdentityOperation } from "./IIdentity";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";
import { gqlAddOrganizationIdentity, gqlOrganizationIdentities, gqlOrganizationIdentitiesConcurrencyCheck, gqlOrganizationSystems, gqlRemoveOrganizationIdentity } from "../Organizations/OrganizationQuery";
import { gqlAddLocationIdentity, gqlLocationIdentities, gqlLocationIdentitiesConcurrencyCheck, gqlLocationSystems, gqlRemoveLocationIdentity } from "../Locations/LocationQuery";

const IdentityList: React.FC<IdentityListProps> = ({identities, systems, api, onSave, onConcurrencyError, onCancel}) => { 
  const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };
  const [data, setData] = useState<IIdentity[]>(identities);
  const [editMode, setEditMode] = useState<boolean>(false);

  React.useEffect(() => {
    setEditMode(false);
    const list: IIdentity[] = [];
    identities.forEach(i => list.push({...i, operation: IdentityOperation.None}));
    setData(list);
  }, [identities]);

  const onRemove = (identity: IIdentity) => { 
    const index = data.indexOf(identity);
    if (index > -1) {
      data[index].operation = IdentityOperation.Remove;
      setData([...data]);
    }
  };

  const addIdentity = (identity: IIdentity) => { 
    setData([...data, identity]);
    return true;
  };

  return (
    <div>
    <div style={{ display: "flex" }}>
    <div style={{ flexGrow: 1}}></div>
    <div style={{ padding: "0.75rem 1rem" }}>
      <Button
        visible={ editMode }      
        width={120}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={() => { onCancel(); setEditMode(false);}}>
        <span className="dx-icon-close" style={{fontSize: "18px"}}></span>Cancel
      </Button>&nbsp;
      <Button
        visible={ !editMode }       
        width={120}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={() => setEditMode(true)}>
        <img src={EditIcon} style={{width: "18px"}}/>Edit
      </Button>
      <Button
        visible={ editMode }              
        width={120}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={() => {onSave(data); setEditMode(false); }} icon="floppy">
        <span className="dx-icon-floppy" style={{fontSize: "18px"}}></span>Save 
      </Button>
    </div>
  </div>
    <div style={formPaddingStyle}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{textAlign: "left"}}>System</th>
            <th style={{textAlign: "left"}}>Value</th>
          </tr>
        </thead>
        <tbody>
          { 
            data.filter(i => i.operation !== IdentityOperation.Remove).map((identity, index) => {
              return (
                <tr key={index}>
                  <td height={36}>{identity.system}</td>
                  <td>{identity.value}</td>
                  <td width={40}><Button icon="trash" visible={ editMode } onClick={()=> onRemove(identity)}></Button></td>
                </tr>
              );
            }) 
          }
        </tbody>
      </table>
      <Button
        visible={ editMode } 
        text="Outlined"
        type="normal"
        stylingMode="outlined" 
        onClick={() => api.showModal('add-identity-modal', { addIdentity, systems, onConcurrencyError })}>Add identity</Button>
      </div>
    </div>
  );
};

export const IdentityPilet: Pilet = {
  name: 'Identity Module',
  version: '1.0.0',
  spec: 'v2',
  dependencies: {},
  config: {},
  basePath: '/pilets',
  link: '/pilets/connector',
  setup(api: PiletApi) { 
    api.registerModal("add-identity-modal", ({ options, onClose }) => { 
      let opts = options as IAddIdentitiesModalOptions;
      const formPaddingStyle: React.CSSProperties = { 
        paddingBottom: "1rem" };

      const [identity, setIdentity] = useState<IIdentity>({ system: "", value: "", operation: IdentityOperation.Add });
      const [systems, setSystems] = useState<string[]>([]);

      React.useEffect(() => {
        setSystems(opts.systems);
      }, [opts.systems]);

      const onNewSystem = async (e: any) => {
        var newSystems = [...systems];
        newSystems.push(e.text);
        setSystems(newSystems);

        var newIdentity = { ...identity };
        newIdentity.system = e.text;
        setIdentity(newIdentity);
      };

      const handleValueChanged = async (e, field) => { 
        var newIdentity = { ...identity };
        newIdentity[field] = e;
        setIdentity(newIdentity);
      };

      const addIdentity = async () => {
        if (!await opts.onConcurrencyError(identity))
        {
          if(opts.addIdentity(identity)) 
          { 
            onClose();
          } 
        } else {
          alert("Concurrency error");
        }
      }

      return(
        <div className="modal-container">
          <div className="modal" style={{ height: 460}}>
            <div className="modal-header">
              <div className="modal-header-text">Add Identity</div>
              <div className="modal-header-right">
              <a
                className="modal-button-close"
                onClick={ onClose }
                data-testid="aboutCloseButton"
              ></a>
              </div>
            </div>
            <div className="modal-body">
            <form>
              <div style={{padding: "0 1rem 1rem 1rem"}}>
                <div style={formPaddingStyle}><SelectBox label='System' items={systems} onValueChange={ (e) => handleValueChanged(e, "system") } labelMode="floating" stylingMode="outlined" acceptCustomValue={true} onCustomItemCreating={ onNewSystem } value={ identity.system }></SelectBox></div>
                <div style={formPaddingStyle}><TextBox label='Value' onValueChange={ (e) => handleValueChanged(e, "value") } labelMode="floating" stylingMode="outlined" value={ identity.value }></TextBox></div>
                <div style={{ fontSize: 16, fontWeight:"bold", display: "flex", justifyContent: "space-between" }}>
                  <Button
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined"
                    onClick={addIdentity}>Add identity</Button>
                  <Button
                    text="Outlined"
                    type="normal"
                    stylingMode="outlined" 
                    onClick={onClose}>Cancel</Button>
                  </div>
              </div>
            </form>
          </div>
          </div>
        </div>
      );
    });

    api.registerExtension('user-identities', ({ params }) => {
      const [userId, setUserId] = useState<string>('');
      const [identities, setIdentities] = useState([]);
      const [systems, setSystems] = useState([]);

      React.useEffect(() => {
        api.on('store-data', async ({ name, value }) => {
          if (name === 'selectedUser' && value != '') {
            setUserId(value);
            await refreshData(value);
            console.log(`New value is "${value}"!`);
          }
        });
      }, []);

      const refreshData = async (userId) => { 
        const result = await query<any>(gqlUserIdentities, { userId });
        setIdentities(result[0].identities);

        const result2 = await query<any>(gqlUserSystems, { userId });
        setSystems(result2.map(s => s.system));
      };

      const onCancel = async () => { 
        await refreshData(userId);
      };

      const onConcurrencyError = async (i: IIdentity) => { 
        const result = await query<any>(gqlUserIdentitiesConcurrencyCheck, {identity : { system: i.system, values: [i.value]}});
        return result.length > 0;
      };

      const onSave = async (i: IIdentity[]) => {
        let result: any = {};

        for (let id of i) {
          if (id.operation == IdentityOperation.Add)
          {
            result = await mutation<any>(gqlAddUserIdentity, { input: { id: userId, identity: {system: id.system, value: id.value} } });
          }

          if (id.operation == IdentityOperation.Remove)
          {
            result = await mutation<any>(gqlRemoveUserIdentity, { input: { identity: {system: id.system, value: id.value} } });
          }
        }
        
        setIdentities(result.identities);
      };

      return (
        <IdentityList identities={identities} systems={systems} api={api} onCancel={onCancel} onSave={(i) => onSave(i)} onConcurrencyError={(i) => onConcurrencyError(i)}/>
      );
    }, { type: 'user' , sortOrder: 3, name: "Identities", icon: IdentityIcon });

    api.registerExtension('location-identities', ({ params }) => {
      const [locationId, setLocationId] = useState<string>('');
      const [identities, setIdentities] = useState([]);
      const [systems, setSystems] = useState([]);

      React.useEffect(() => {
        api.on('store-data', async ({ name, value }) => {
          if (name === 'selectedLocation'  && value != '') {
            setLocationId(value);
            await refreshData(value);
            console.log(`New value is "${value}"!`);
          }
        });
      }, []);

      const refreshData = async (locationId) => { 
        const result = await query<any>(gqlLocationIdentities, { locationId });
        setIdentities(result[0].identities);

        const result2 = await query<any>(gqlLocationSystems, { locationId });
        setSystems(result2.map(s => s.system));
      };

      const onCancel = async () => { 
        await refreshData(locationId);
      };

      const onConcurrencyError = async (i: IIdentity) => { 
        const result = await query<any>(gqlLocationIdentitiesConcurrencyCheck, {identity : { system: i.system, values: [i.value]}});
        return result.length > 0;
      };

      const onSave = async (i: IIdentity[]) => {
        let result: any = {};

        for (let id of i) {
          if (id.operation == IdentityOperation.Add)
          {
            result = await mutation<any>(gqlAddLocationIdentity, { input: { id: locationId, identity: {system: id.system, value: id.value} } });
          }

          if (id.operation == IdentityOperation.Remove)
          {
            result = await mutation<any>(gqlRemoveLocationIdentity, { input: { identity: {system: id.system, value: id.value} } });
          }
        }
        
        setIdentities(result.identities);
      };

      return (
        <IdentityList identities={identities} systems={systems} api={api} onCancel={onCancel} onSave={(i) => onSave(i)} onConcurrencyError={(i) => onConcurrencyError(i)}/>
      );
    }, { type: 'location' , sortOrder: 3, name: "Identities", icon: IdentityIcon });

    api.registerExtension('organization-identities', ({ params }) => {
      const [organizationId, setOrganizationId] = useState<string>('');
      const [identities, setIdentities] = useState([]);
      const [systems, setSystems] = useState([]);

      React.useEffect(() => {
        api.on('store-data', async ({ name, value }) => {
          if (name === 'selectedOrganization' && value != '') {
            setOrganizationId(value);
            await refreshData(value);
            console.log(`New value is "${value}"!`);
          }
        });
      }, []);

      const refreshData = async (organizationId) => { 
        const result = await query<any>(gqlOrganizationIdentities, { organizationId });
        setIdentities(result[0].identities);

        const result2 = await query<any>(gqlOrganizationSystems, { organizationId });
        setSystems(result2.map(s => s.system));
      };

      const onCancel = async () => { 
        await refreshData(organizationId);
      };

      const onConcurrencyError = async (i: IIdentity) => { 
        const result = await query<any>(gqlOrganizationIdentitiesConcurrencyCheck, {identity : { system: i.system, values: [i.value]}});
        return result.length > 0;
      };

      const onSave = async (i: IIdentity[]) => {
        let result: any = {};

        for (let id of i) {
          if (id.operation == IdentityOperation.Add)
          {
            result = await mutation<any>(gqlAddOrganizationIdentity, { input: { id: organizationId, identity: {system: id.system, value: id.value} } });
          }

          if (id.operation == IdentityOperation.Remove)
          {
            result = await mutation<any>(gqlRemoveOrganizationIdentity, { input: { identity: {system: id.system, value: id.value} } });
          }
        }
        
        setIdentities(result.identities);
      };

      return (
        <IdentityList identities={identities} systems={systems} api={api} onCancel={onCancel} onSave={(i) => onSave(i)} onConcurrencyError={(i) => onConcurrencyError(i)}/>
      );
    }, { type: 'organization' , sortOrder: 3, name: "Identities", icon: IdentityIcon });
  }
};