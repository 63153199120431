import { DashboardContainerProps } from 'piral';
import * as React from 'react';

export const DashboardContainer : React.FC<DashboardContainerProps> = ({ children }) => (
  <div style={{ padding: "30px"}}>
    <h5>HOME</h5>
    <p>Welcome to your home screen!</p>
    <div className="tiles">
      {children}
    </div>
  </div>
);