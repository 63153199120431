import * as React from "react";
import { Pilet, PiletApi, useGlobalState } from "piral-core";
import { useState } from "react";
import Accordion from "devextreme-react/accordion";
import "./Extension.scss";

export const ExtensionsPilet: Pilet = {
  name: "Extension Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension("extension-list", ({ params }) => {
      const extensions = useGlobalState((s) => s.registry.extensions);
      const [extensionNames, setExtensionNames] = useState([]);

      function ExtensionBodyHtml(extensionName) {
        return <api.Extension name={extensionName}></api.Extension>;
      }

      function ExtensionHeaderHtml(extensionName) {
        return (
          <div className="header">
            <img style={{ minWidth: 20, marginRight: 5 }} src={extensions[extensionName][0].defaults?.icon}></img>
            {extensions[extensionName][0].defaults?.name}
          </div>
        );
      }

      React.useEffect(() => {
        const extensionNamesTemp = Object.keys(extensions)
          .filter((extension) => params.extensions?.includes(extensions[extension][0].defaults?.type))
          .sort((a, b) => extensions[a][0].defaults?.sortOrder - extensions[b][0].defaults?.sortOrder);

        setExtensionNames(extensionNamesTemp);
      }, [extensions]);

      // Somehow, the accordion takes up more space the more items it has.
      // Until we figure out the proper way to set the height, we do this magic calculation here.
      const heightOffset = extensionNames.length * 12;
      const height = `calc(100% - ${heightOffset}px)`;

      return (
        <Accordion
          collapsible={true}
          items={extensionNames}
          itemRender={ExtensionBodyHtml}
          noDataText=""
          itemTitleRender={ExtensionHeaderHtml}
          deferRendering={false}
          height={height}
        />
      );
    });
  },
};
