import * as React from 'react';
import { Link } from 'react-router-dom';
import { ComponentsState, ErrorComponentsState, Menu, Notifications, SwitchErrorInfo, MenuItemProps, Modals } from 'piral';

import { DashboardContainer } from './Components/Dashboard/DashboardContainer'
import { MenuContainer, MenuItem } from './Components/ApplicationMenu/MenuContainer';
import { Header } from './Components/Header/Header';
import { Footer } from './Components/Footer/Footer';


export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">Could not find the requested page. Are you sure it exists?</p>
      <p>
        Go back <Link to="/">to the dashboard</Link>.
      </p>
    </div>
  ),
};

export const layout: Partial<ComponentsState> = {
  ErrorInfo: (props) => (
    <div>
      <h1>Error</h1>
      <SwitchErrorInfo {...props} />
    </div>
  ),
  LoadingIndicator: () => <div>Loading</div>,
  DashboardContainer: DashboardContainer,
  DashboardTile: ({ columns, rows, children }) => <div className={`tile cols-${columns} rows-${rows}`}>{children}</div>,
  Layout: ({ children }) => (
      <div className='container-page'>
        <Modals />
        <Notifications />
        <Header/>
        <div className="inner-page">
          <Menu type="general" />
          <div className="container">{children}</div>
        </div>
        <Footer/>
      </div>
  ),
  MenuContainer: MenuContainer,
  MenuItem: MenuItem,
  NotificationsHost: ({ children }) => <div className="notifications">{children}</div>,
  NotificationsToast: ({ options, onClose, children }) => (
    <div className={`notification-toast ${options.type}`}>
      <div className="notification-toast-details">
        {options.title && <div className="notification-toast-title">{options.title}</div>}
        <div className="notification-toast-description">{children}</div>
      </div>
      <div className="notification-toast-close" onClick={onClose} />
    </div>
  ),
};
