export const priorities = [{id: "INFO", name: "Info"}, {id: "LOW", name: "Low"}, {id: "MEDIUM", name: "Medium"}, {id: "HIGH", name: "High"}, {id: "ALARM", name: "Alarm"}];
export const colors = [
  { id: "RED", name: "Red" },
  { id: "GREEN", name: "Green" },
  { id: "BLUE", name: "Blue" },
  { id: "YELLOW", name: "Yellow" },
  { id: "ORANGE", name: "Orange" },
  { id: "PURPLE", name: "Purple" },
  { id: "PINK", name: "Pink" },
  { id: "BROWN", name: "Brown" },
  { id: "BURGUNDY", name: "Burgundy" },
  { id: "CYAN", name: "Cyan" },
  { id: "GREY", name: "Grey" },
  { id: "BLACK", name: "Black" },
  { id: "WHITE", name: "White" },
];

export function colorTranslation(color: string): string {
  switch(color) {
    case "BURGUNDY":
      return "#4C1C24";
    case "GREY":
      return "GRAY";
    default:
      return color;
  }
}