import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Button } from "devextreme-react";
import { logout } from "../../Services/client";

export const LogoutConfirmationModel: Pilet = {
  name: "Logout Confirmation Modal Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerModal("logout-confirmation-modal", ({ onClose }) => (
      <div className="modal-container">
        <div className="modal" style={{ height: "auto" }}>
          <div className="modal-header">
            <div className="modal-header-text">Confirm logout</div>
            <div className="modal-header-right">
              <a
                className="modal-button-close"
                onClick={onClose}
                data-testid="aboutCloseButton"
              ></a>
            </div>
          </div>
          <div className="modal-body" style={{ paddingLeft: 18 }}>
            Are you sure you want to log out? <br />
            <div style={{marginBottom: 16, marginTop: 16, gap: 8, display: "flex"}}>
              <Button
                text="Outlined"
                type="normal"
                stylingMode="outlined"
                onClick={() => {
                  logout();
                }}
              >
                Yes
              </Button>
              <Button
                text="Outlined"
                type="normal"
                stylingMode="outlined"
                onClick={(e) => {
                  onClose();
                }}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    ));
  },
};
