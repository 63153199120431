import { isNullOrUndefined } from "../util/nullUtilities";

export interface IConfig {
  identityProviderUri: string;
  clientId: string;
  client_secret: string;
  feedUri: string;
  apiBaseUri: string;
}

export const defaultConfig: IConfig = {
  "identityProviderUri":"http://localhost:8080/realms/daniel/protocol/openid-connect",
  "clientId": "aup",
  "client_secret": "BAjYhzVLPHyHIqvaah8x5IlU2JrGZsy9",
  "feedUri": "https://feed.piral.cloud/api/v1/pilet/empty",
  "apiBaseUri": "https://localhost:7230/graphql"
};

let appConfig: IConfig | undefined;

/**
 * @returns The app config.
 */
export function getConfig(): IConfig {
  return appConfig;
}

/**
 * @returns The root URL where the API is running, /api/ must be appended to this.
 */
export function getApiRealBaseUrl() {
  if (isNullOrUndefined(appConfig)) return "";

  return appConfig.apiBaseUri.replace("/graphql", "");
}

/**
 * Loads the app config, first by trying to load the config from the server.
 * If we cannot load the config from the server, we default to the defaultConfig defined in this file.
 * @returns The application config.
 */
export async function loadConfig() {
  console.log("Loading config");

  let configFile = await fetch("config.json", {
    // mode: 'no-cors',
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  try {
    const config = await configFile.json();
    console.log("Config file from server used.");
    appConfig = config;
  } catch {
    console.log("Default config used.");
    appConfig = defaultConfig;
  }
}
