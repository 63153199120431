import { MenuContainerProps, MenuItemProps } from 'piral';
import * as React from 'react';
import { useLocation} from 'react-router-dom';
import CollapseIcon from '../../assets/icons/ic_collapse_24.svg'

export const MenuItem: React.FC<MenuItemProps> = ({ children }) => {
  const arrayChildren = React.Children.map(children, (a: any, b) => a);
  return (<>{arrayChildren}</>)
};

export const MenuContainer: React.FC<MenuContainerProps> = ({ children })  => {
  const [collapsed, setCollapsed] = React.useState(false);
  const arrayChildren = React.Children.toArray(children).sort((a: any, b: any) => a.props.meta.sortOrder > b.props.meta.sortOrder ? 1 : -1);
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
      <div className={`navigation-container ${collapsed ? 'navigation-items-container-collapsed' : 'show'}`}>
        <div className={`navigation-items-container ${collapsed ? 'navigation-items-container-collapsed' : 'show'}`}>
          <ul className={`${collapsed ? 'navigation-item-collapsed' : ''}`}>
            {arrayChildren.map((a: any,b) => <li key={a.props.meta.location} className={`nav-item ${currentRoute == a.props.meta.location ? 'nav-item-active' : ''}`}><div></div>{a}</li>)}
          </ul>
        </div>
        <div className="navigation-toggle">
          <button
            aria-label="Toggle navigation"
            type="button"
            onClick={() => setCollapsed(!collapsed)}>
            <span className="navigation-toggler-icon-rotate" />
            <img className={`${collapsed ? 'navigation-toggler-icon-rotate' : ''}`} src={CollapseIcon}/>
          </button>
        </div>
      </div>
  );
}