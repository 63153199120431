import { graphQLQuery } from "../../Services/GraphQL";

const selectString = `id name priority color icon { id category item url } sound { id category item url }`;
const selectStringLarge = `
        id
        name
        site { id }
        priority
        color
        icon { id category item url }
        sound { id category item url }
        supportedOperations { remoteCancel }
        parameters { name format constraintsType constraints defaultValue}
        priorityOverride {
          color
          priority
          icon { id category item url }
          sound { id category item url }
        }
        locations { id name }`;

export const gqlLocations: graphQLQuery = {
  query: `query locations {
    locations {
      id
      name
      site { id }
      parentId
    }
  }`,
  errorMessage: "Error loading locations.",
  collection: "locations",
};

export const gqlQueryEventTemplates: graphQLQuery = {
  query: `query {
    eventTemplates {
      ${selectString}
    }
  }`,
  errorMessage: "Error loading event templates.",
  collection: "eventTemplates",
};

export const gqlQueryEventTemplate: graphQLQuery = {
  query: `query eventTemplates($eventTemplateId: [UUID!]) {
    eventTemplates(ids: $eventTemplateId) {
      ${selectStringLarge}
    }
  }`,
  errorMessage: "Error loading event templates.",
  collection: "eventTemplates",
};

export const gqlUpdateEventTemplate : graphQLQuery = {
  query: `mutation updateEventTemplate($id: UUID!, $input: UpdateEventTemplateInput!) {
    updateEventTemplate(id: $id, input: $input) {
      eventTemplate {
        ${selectStringLarge}
      }
    }
  }`,
  errorMessage: "Error loading event template.",
  collection: "updateEventTemplate.eventTemplate"
};

export const gqlAddEventTemplate : graphQLQuery = {
  query: `mutation addEventTemplate($input: AddEventTemplateInput!) {
    addEventTemplate(input: $input) {
      eventTemplate {
        ${selectStringLarge}
      }
    }
  }`,
  errorMessage: "Error loading event template.",
  collection: "addEventTemplate.eventTemplate"
};

export const gqlQueryEventTemplateDependencies : graphQLQuery = {
  query: `query eventTemplates($eventTemplateIds: [UUID!]) {
    eventTemplates(ids: $eventTemplateIds) {
      id
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading eventTemplate dependencies.",
  collection: "eventTemplates"
};

export const gqlDeleteEventTemplate : graphQLQuery = {
  query: `mutation deleteEventTemplate($input: DeleteEventTemplateInput!) {
    deleteEventTemplate(input: $input) {
      eventTemplate {
        id
      }
    }
  }`,
  errorMessage: "Error deleting event template.",
  collection: "deleteEventTemplate.eventTemplate"
};