import * as React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/AscomSharedAdmin_Logo_36px.svg';
import SmallLine from '../../assets/icons/line.svg';
import { SiteSelector } from './SiteSelector';
import { TenantSelector } from './TenantSelector';
import { LogoutButton } from './LogoutButton';
import { CurrentUser } from './CurrentUser';

export const Header = () => {
  const logoStyle = {
    margin: "0.88rem",
    height: "30px",
    width: "30px",
    backgroundImage: 'url(' + Logo + ')',
  };

  const applicationNameStyle = {
    marginTop: "0.94rem",
    height: "36px",
    width: "300px"
  };

  const logoWrapperStyle = {
    width: "400px",
    display: "flex"
  };

  const userWrapperStyle = {
    display: "flex",
    justifyContent: "right",
    alignItems: "center"
  };

  const largeDivider = {
    width: "1.61rem",
    height: "2rem",
    backgroundImage: 'url(' + SmallLine + ')',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  };

  return (
    <header>
      <div className="header-container">
        <div style={ logoWrapperStyle }>
          <div style={ logoStyle }>
          </div>
          <div style={ applicationNameStyle }>
            <Link className="header-brand" to="/">
              Ascom Admin
            </Link>
          </div>
        </div>
        <div style={ userWrapperStyle }>
          <div style={{paddingRight: '1rem'}}>
              <TenantSelector/>
            </div>
          <div>
            <SiteSelector/>
          </div>
          <div style={largeDivider}>
          </div>
          <div>
            <CurrentUser />
          </div>
          <div style={largeDivider}>
          </div>
          <LogoutButton />
        </div>
      </div>
    </header>
  );
};