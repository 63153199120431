import React from "react";
import { logout } from "../Services/client";

export const Unauthorized: React.FC = () => {
  const pageStyle: React.CSSProperties = {
    height: "100%",
    padding: "30px",
  };

  const handleClick = () => {
    logout();
  };

  return (
    <div style={pageStyle}>
      <h1>Access Denied</h1>
      <p>You do not have permission to access Ascom Admin.</p>
      <button onClick={handleClick}>Logout</button>
    </div>
  );
};
