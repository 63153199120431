import { graphQLQuery } from "../../Services/GraphQL";

export const gqlSites : graphQLQuery = {
  query: `query {
    sites {
      id
      name
    }
  }`,
  errorMessage: "Error loading sites.",
  collection: "sites"
};

export const gqlSite : graphQLQuery = {
  query: `query sites($siteId: [UUID!]) {
    sites(ids: $siteId) {
      id
      name
    }
  }`,
  errorMessage: "Error loading site.",
  collection: "sites"
};

export const gqlUpdateSite : graphQLQuery = {
  query: `mutation updateSite($id: UUID!, $input: UpdateSiteInput!) {
    updateSite(id: $id, input: $input) {
      site {
        id
        name
      }
    }
  }`,
  errorMessage: "Error loading site.",
  collection: "updateSite.site"
};

export const gqlAddSite : graphQLQuery = {
  query: `mutation addSite($input: AddSiteInput!) {
    addSite(input: $input) {
      site {
        id
        name
      }
    }
  }`,
  errorMessage: "Error loading site.",
  collection: "addSite.site"
};

export const gqlDeleteSite : graphQLQuery = {
  query: `mutation deleteSite($input: DeleteSiteInput!) {
    deleteSite(input: $input) {
      site {
        id
      }
    }
  }`,
  errorMessage: "Error deleting site.",
  collection: "deleteSite.site"
};