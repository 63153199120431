import { TextBox } from 'devextreme-react/text-box';
import * as React from 'react';
import SiteIcon from '../../assets/icons/ic_site_24px_dark.png';

const customItem: React.CSSProperties = {
  display: "flex",
  marginLeft: "18px",
  textIndent: "0",
  lineHeight: "30px",
  verticalAlign: "top",
  width: "225px",
};

const customImage: React.CSSProperties = {
  width: "25px",
  height: "25px",
  marginTop: "-12px",
  position: "absolute",
  top: "50%"
};

const customText: React.CSSProperties = {
  height: "25px",
  marginTop: "-7px",
  marginLeft: 15,
  position: "absolute",
};

export function Site(data) {
  if (!data) return (<></>);

  const image = SiteIcon;
  return (
    <><span style={{display: "inline-block"}}><img style={{width: "25px", height: "25px"}} src={image}/></span><span style={{display: "inline-block", verticalAlign: "top", marginTop: "5px", marginLeft: "10px"}}>{data.name}</span></>
  );
}

export function SiteField(data) {
  if (!data) return (<><TextBox></TextBox></>);
  
  const image = SiteIcon;
  return (
      <div style={customItem}><div><img style={customImage} src={image}/></div><div style={customText}><TextBox style={{width: 250}} defaultValue={data.name} readOnly={true} /></div></div>
  );
}