import * as React from "react";
import LogoutImage from "../../assets/icons/ic_logout_black_24px.svg";
import { instance } from "../..";

export const LogoutButton = () => {
  const logoutButtonStyle: React.CSSProperties = {
    backgroundColor: "transparent",
    borderStyle: "none",
    height: "2.25rem",
    width: "7rem",
    cursor: "pointer",
  };

  const logoutStyle: React.CSSProperties = {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.25rem",
    letterSpacing: "0.04rem",
    textTransform: "uppercase",
    backgroundColor: "transparent",
    borderStyle: "none",
    display: "flex",
    alignItems: "center",
  };

  const textStyle = {
    marginTop: "2px",
  };

  const rootApi = instance.root;

  const handleClick = () => {
    rootApi.showModal("logout-confirmation-modal");
  };

  return (
    <button
      className="waves-effect"
      style={logoutButtonStyle}
      type="button"
      onClick={handleClick}
    >
      <div style={logoutStyle}>
        <img src={LogoutImage} />
        <div style={textStyle}>Log out</div>
      </div>
    </button>
  );
};
