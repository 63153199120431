import { client, instance } from '../../index';

export async function query<T>(query: graphQLQuery, variables?: object): Promise<T> {
  try {
    const result = variables ? await client.query(query.query, variables).toPromise() : await client.query(query.query).toPromise();
    
    if (!result.data) throw new Error(result.error.message);
    
    return getData(result.data, query.collection);
  } catch (error) {
    instance.root.showNotification(query.errorMessage, { type: 'error' });
    console.error("Error refreshing data:", error);
  }
}

export async function mutation<T>(mutation: graphQLQuery, variables: object): Promise<T> {
  try {
    const result = variables ? await client.mutation(mutation.query, variables).toPromise() : await client.mutation(mutation.query).toPromise();

    if (!result.data) throw new Error(result.error.message);

    return getData(result.data, mutation.collection);
  } catch (error) {
    instance.root.showNotification(mutation.errorMessage, { type: 'error' });
    console.error("Error refreshing data:", error);
  }
}

function getData(obj: any, path: string): any {
  const levels = path.split('.');
  let value = obj;

  for (const level of levels) {
    value = Reflect.get(value, level);
    if (value === undefined) {
      break;
    }
  }

  return value;
}

export interface graphQLQuery {
  query: string;
  errorMessage: string;
  collection: string;
}