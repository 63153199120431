import { graphQLQuery } from "../../Services/GraphQL";

export const gqlUsers : graphQLQuery = {
  query: `query {
    users {
      id
      firstName
      lastName
      initials
      middleName
      email
      avatar { url }
    }
  }`,
  errorMessage: "Error loading users.",
  collection: "users"
};

export const gqlQueryPaginatedUsers : graphQLQuery = {
  query: `query paginatedUsers($first: Int!, $after: String!) {
    paginatedUsers(first: $first, after: $after) {
      nodes {
        id
        firstName
        lastName
        initials
        middleName
        email
        avatar { url }
      },
      pageInfo { hasNextPage, startCursor, endCursor},
      totalCount
    }
  }`,
  errorMessage: "Error loading paginated users.",
  collection: "paginatedUsers"
};

export const gqlUser : graphQLQuery = {
  query: `query users($userId: [UUID!]) {
    users(ids: $userId) {
      id
      firstName
      lastName
      initials
      middleName
      email
      sites { id }
    }
  }`,
  errorMessage: "Error loading users.",
  collection: "users"
};

export const gqlQueryUserDependencies : graphQLQuery = {
  query: `query users($userIds: [UUID!]) {
    users(ids: $userIds) {
      id
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading user dependencies.",
  collection: "users"
};

export const gqlUserIdentities : graphQLQuery = {
  query: `query users($userId: [UUID!]) {
    users(ids: $userId) {
      identities {
        system
        value
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "users"
};

export const gqlUserIdentitiesConcurrencyCheck : graphQLQuery = {
  query: `query users($identity: IdentityFilter) {
    users(identities: $identity) {
      id
      identities {
        system
        value
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "users"
};

export const gqlAddUserIdentity : graphQLQuery = {
  query: `mutation addUserIdentity($input: AddIdentityInput!) {
    addUserIdentity(input: $input) {
      user {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "addUserIdentity.user"
};

export const gqlRemoveUserIdentity : graphQLQuery = {
  query: `mutation removeUserIdentity($input: RemoveIdentityInput!) {
    removeUserIdentity(input: $input) {
      user {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "removeUserIdentity.user"
};

export const gqlUserAvatar : graphQLQuery = {
  query: `query users($userId: [UUID!]) {
    users(ids: $userId) {
      id
      avatar { url }
    }
  }`,
  errorMessage: "Error loading users.",
  collection: "users"
};

export const gqlUserSystems : graphQLQuery = {
  query: `query {
    userIdentitySystems { system }
  }`,
  errorMessage: "Error loading user systems.",
  collection: "userIdentitySystems"
};

export const gqlUpdateUser : graphQLQuery = {
  query: `mutation updateUser($id: UUID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        firstName
        lastName
        initials
        middleName
        email
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "updateUser.user"
};

export const gqlDeleteUser : graphQLQuery = {
  query: `mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }`,
  errorMessage: "Error deleting user.",
  collection: "deleteUser.user"
};

export const gqlAddUser : graphQLQuery = {
  query: `mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      user {
        id
        firstName
        lastName
        initials
        middleName
        email
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "addUser.user"
};

export const gqlRemoveUserFromSite : graphQLQuery = {
  query: `mutation RemoveUserFromSite($input: SiteInput!) {
    removeUserFromSite(input: $input) {
      user {
        id
        firstName
        lastName
        initials
        middleName
        email
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "removeSiteFromUser.user"
};

export const gqlAddUserToSite : graphQLQuery = {
  query: `mutation AddUserToSite($input: SiteInput!) {
    addUserToSite(input: $input) {
      user {
        id
        firstName
        lastName
        initials
        middleName
        email
      }
    }
  }`,
  errorMessage: "Error loading user.",
  collection: "addUserToSite.user"
};