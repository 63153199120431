import * as React from 'react';
import { Pilet, PiletApi } from 'piral-core';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import SiteIcon from '../../assets/icons/ic_locations_buildings_24px.svg';
import { gqlSites } from './SiteQuery';
import { query } from '../../Services/GraphQL';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Tabs from 'devextreme-react/tabs';
import { ISite } from './ISite';

export const SitePilet: Pilet = {
  name: 'Site Module',
  version: '1.0.0',
  spec: 'v2',
  dependencies: {},
  config: {},
  basePath: '/pilets',
  link: '/pilets/connector',
  setup(api: PiletApi) {
    api.registerMenu("Sites",
      () => (
        <Link to="/sites">
          <div>
            <div><img src={SiteIcon}></img></div>
            <div>Sites</div>
          </div>
        </Link>
      ),
      { type: 'general' , sortOrder: 0, location: "/sites" },
    );

    api.registerTile("Sites", () => {
      const smallTileStyle: React.CSSProperties = {
        backgroundImage: 'url(' + SiteIcon + ')',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#d8d8d8",
        backgroundSize: "cover",
        borderRadius: "0.5rem",
        verticalAlign: "middle",
        textAlign: "center",
        paddingTop: "40px"
      };
      return (
        <div className="tile">
          <div style={smallTileStyle}>
            <Link to="/sites">Sites</Link>
            <br />
            Administration of sites.
          </div>
        </div>
      );
    }, { initialColumns: 2, initialRows: 2 });

    api.registerPage('/sites', ({ children }) => {
      const [sites, setSites] = useState<ISite[]>([]);

      const refreshData = async () => {
        const result = await query<ISite[]>(gqlSites);
        setSites(result);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on('refresh', data => {
          if (data.name === 'site') {
            refreshData();
            api.setData('selectedSite', '');
          }
        });

        api.on('store-data', ({ name, value }) => {
          if (name === 'currentSite') {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
          }
        });
      }, []);

      const onRowSelected = (row) => {
        api.setData('selectedSite', row.selectedRowsData.length === 0 ? '' : row.currentSelectedRowKeys[0]);
      };

      const tabs = [
        {
          id: 0,
          text: 'Site',
          icon: SiteIcon,
          content: 'Site content'
        },
      ];

      return (
        <>
        <div style={{display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem"}}>
          <div style={{margin: "1rem 1rem 0 1rem", backgroundColor: "#FFF"}}><Tabs style={{marginLeft: "1rem"}} dataSource={tabs} width={300} selectedItem={tabs[0]}></Tabs></div>
          <div style={{display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF"}}>
            <div className="list-items-container">
            <DataGrid
              onSelectionChanged={onRowSelected}
              selection={{ mode: 'single' }}
              dataSource={sites}
              keyExpr="id"
              showBorders={true}
            >
              <Column
                name='Name'
                dataField="name"
                dataType="string"
              />
            </DataGrid>
            </div>
            <div className="extension-list">
              <api.Extension name="extension-list" params={{extensions: "site"}}></api.Extension>
            </div>
          </div>
          </div>
        </>
      )
    });
  }
};