import * as React from 'react';
import { client, instance } from '../../index';
import { useState } from 'react';
import SelectBox from 'devextreme-react/select-box';

export const TenantSelector = () => {
  const [tenants, setTenants] = useState([]);
  const [tenant, setTenant] = useState('');

  const tenantSelectorStyle: React.CSSProperties = {
    height: "2.25rem",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.25rem",
    letterSpacing: "0.04rem",
    textTransform: "uppercase",
    border: "1px solid rgba(23, 53, 68, 0.60)",
    borderRadius: "0.25rem",
    width: "11.375rem"
  };

  const gql = `query {
    tenants {
      id
      name
    }
  }`;

  React.useEffect(() => {
    instance.root.setData("currentSite", "*");

    instance.root.on('refresh', data => { 
      if (data.name === 'tenants') {
        refreshData();
      }
    });

    refreshData();
  }, [])

  const refreshData = async () => {
    const result = await client.query(gql).toPromise();
    setTenants([...result.data.tenants]);
    setTenant(result.data.tenants[0].id);
    instance.root.setData("currentTenant", result.data.tenants[0].id);
    instance.root.emit("refresh", { name: "tenant", value: result.data.tenants[0].id });
  };

  const handleClick=(id) => {
    console.log("Set data: " + id);
    setTenant(id);
    instance.root.setData("currentTenant", id);
    instance.root.emit("refresh", { name: "tenant", value: id });
  };

  return (
    <SelectBox visible={tenants.length > 1} style={{ height: "35px"}} items={tenants} value={tenant} displayExpr="name" valueExpr="id" stylingMode="outlined" label='Tenant' defaultValue={"*"} onValueChange={handleClick}></SelectBox>
  );
};