import * as React from 'react';
import { Pilet, PiletApi } from 'piral-core';
import { Link } from 'react-router-dom';
import OrganizationIcon from '../assets/icons/ic_my_units_24px.svg'; 
import LocationIcon from '../assets/icons/ic_locations_buildings_24px.svg'; 
import UserIcon from '../assets/icons/ic_user_24px.svg'; 
import DeviceIcon from '../assets/icons/ic_devices_24px.svg'; 

export const MenuPilet: Pilet = {
  name: 'Menu Module',
  version: '1.0.0',
  spec: 'v2',
  dependencies: {},
  config: {},
  basePath: '/pilets',
  link: '/pilets/connector',
  setup(api: PiletApi) { 

  }};