import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import { query, mutation } from "../../Services/GraphQL";
import { Validator, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import AvatarIcon from "../../assets/icons/ic_user_account_24px.svg";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { EmailRule } from "devextreme-react/data-grid";
import { TagBox } from "devextreme-react/tag-box";
import {
  gqlAddUser,
  gqlDeleteUser,
  gqlQueryUserDependencies,
  gqlUpdateUser,
  gqlUser,
  gqlUserAvatar,
} from "./UserQuery";
import FileUploader from "devextreme-react/file-uploader";
import { gqlSites } from "../Sites/SiteQuery";
import { IUser } from "./IUser";
import {
  deleteEntityGeneric,
  shouldShowDeleteConfirmationModal as shouldShowConfirmDeleteModal,
} from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ISite } from "../Sites/ISite";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";
import { getApiRealBaseUrl } from "../../Services/config";

export const UserPropertiesPilet: Pilet = {
  name: "User Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "user-properties",
      ({ params }) => {
        const emptyUser: IUser = {
          id: "",
          firstName: "",
          lastName: "",
          initials: "",
          middleName: "",
          email: "",
          sites: [],
          dependencies: [],
        };

        const [userToSave, setUserToSave] = useState({});
        const [user, setUser] = useState<IUser>(emptyUser);
        const [userSites, setUserSites] = useState([]);
        const [sites, setSites] = useState<ISite[]>([]);
        const [editMode, setEditMode] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        React.useEffect(() => {
          refreshSiteData();

          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedUser") {
              await refreshData(value);
              console.log(`New value is "${value}"!`);
            }

            if (name === "currentTenant") {
              refreshSiteData();
            }
          });
        }, []);

        const refreshSiteData = async () => {
          const result = await query<ISite[]>(gqlSites);
          setSites(result);
        };

        const refreshData = async (userId) => {
          setEditMode(false);

          if (userId === "") {
            setUser(emptyUser);
            return;
          }

          const result = await query<IUser[]>(gqlUser, { userId: [userId] });
          if (result) {
            setUser(result[0]);
            setUserSites(result[0].sites.map((site) => site.id));
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newUser = { ...user };
            newUser[field] = e.value;
            setUser(newUser);

            var newUserToSave = { ...userToSave };
            newUserToSave[field] = e.value;
            setUserToSave(newUserToSave);
          }
        };

        const handleSiteValueChanged = async (e) => {
          setUserSites(e);
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          userToSave["siteIds"] = userSites;

          try {
            if (user.id === "*") {
              const result = await mutation<IUser>(gqlAddUser, {
                input: userToSave,
              });

              if (result) {
                setUser(result);
                setEditMode(!editMode);
                api.emit("refresh", { name: "user", value: result.id });
              } else {
                api.showNotification("Error adding user", { type: "error" });
              }

              return;
            }

            const result = await mutation<IUser>(gqlUpdateUser, {
              id: user.id,
              input: userToSave,
            });

            if (result) {
              setUser(result);
              api.emit("refresh", { name: "user", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating user", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const deleteUser = async () => {
          const result = await deleteEntityGeneric<IUser>(
            api,
            user.id,
            "user",
            gqlQueryUserDependencies,
            gqlDeleteUser
          );

          if (result === true) {
            setUser(emptyUser);
          }
        };

        const onAddClick = () => {
          setEditMode(true);
          setUser({ ...emptyUser, id: "*" });
          setUserToSave({});
        };

        if (user.id === "")
          return (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <div style={{ padding: "0.75rem 1rem" }}>
                  <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                    <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>
                    Add New
                  </Button>
                </div>
              </div>
            </div>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRow
                  editMode={editMode}
                  onCancel={() => {
                    if (user.id == "*") {
                      setUser({ ...emptyUser });
                    } else {
                      refreshData(user.id);
                    }

                    setEditMode(false);
                  }}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteUser()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="First name"
                    onValueChanged={(e) => handleValueChanged(e, "firstName")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={user.firstName}
                  >
                    <Validator>
                      <RequiredRule message="First name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Last name"
                    onValueChanged={(e) => handleValueChanged(e, "lastName")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={user.lastName}
                  >
                    <Validator>
                      <RequiredRule message="Last name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Initials"
                    onValueChanged={(e) => handleValueChanged(e, "initials")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={user.initials}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 5 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Middle name"
                    onValueChanged={(e) => handleValueChanged(e, "middleName")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={user.middleName}
                  >
                    <Validator>
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Email"
                    onValueChanged={(e) => handleValueChanged(e, "email")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={user.email}
                  >
                    <Validator>
                      <EmailRule message="Not a valid email address"></EmailRule>
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TagBox
                    readOnly={!editMode}
                    label="Sites"
                    labelMode="floating"
                    stylingMode="outlined"
                    items={sites}
                    displayExpr="name"
                    valueExpr="id"
                    value={userSites}
                    placeholder="Choose Site..."
                    onValueChange={(e) => handleSiteValueChanged(e)}
                  >
                    <Validator>
                      <RequiredRule message="Site is required" />
                    </Validator>
                  </TagBox>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "user", sortOrder: 1, name: "User properties", icon: InfoIcon }
    );

    api.registerExtension(
      "user-avatar",
      ({ params }) => {
        const emptyUser = {
          id: "",
          avatar: { url: "" },
        };
        const [user, setUser] = useState(emptyUser);

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedUser") {
              await refreshData(value);
              console.log(`New value is "${value}"!`);
            }
          });
        }, []);

        const refreshData = async (userId) => {
          if (userId === "") {
            setUser(emptyUser);
            return;
          }

          const result = await query<any[]>(gqlUserAvatar, {
            userId: [userId],
          });

          if (result) setUser(result[0]);
        };

        const onSelectedFilesChanged = () => {
          api.emit("refresh", { name: "user", value: user.id });
          refreshData(user.id);
        };

        if (user.id === "") return <>No selected user</>;

        return (
          <>
            {!user.avatar ? (
              <>No avatar uploaded</>
            ) : (
              <img style={{ maxWidth: "95%", borderRadius: "4px" }} src={user.avatar.url + "&width:300"}></img>
            )}
            <FileUploader
              uploadMethod="POST"
              uploadHeaders={{
                authorization: "Bearer " + api.getData("accessToken"),
              }}
              multiple={false}
              accept="*"
              uploadMode="instantly"
              uploadUrl={getApiRealBaseUrl() + "/api/Avatars/" + user.id}
              onFilesUploaded={onSelectedFilesChanged}
              width={430}
            />
          </>
        );
      },
      { type: "user", sortOrder: 2, name: "Avatar", icon: AvatarIcon }
    );
  },
};
